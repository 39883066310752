import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState/EmptyState"
import { useTranslate } from "src/i18n/useTranslate"
import hallway from "src/ui/illustrations/hallway_grey.png"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { AppStoreLogos } from "./AppStoreLogos"

export function NoSensors() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyState
      icon={<img src={hallway} alt="Hallway illustration" />}
      title={t(langKeys.sensors_list_empty_state_title)}
      body={
        <Box>
          {t(langKeys.sensors_list_empty_state_body)}
          <AppStoreLogos />
        </Box>
      }
    />
  )
}

export function NoSensorsOnFilter() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyState
      icon={<img src={hallway} alt="Hallway illustration" />}
      title={t(langKeys.search_nothing_found)}
    />
  )
}

export function NoSensorsEmptyState() {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="bodyS" color="secondary" marginBottom={spacing.L}>
        {t(langKeys.no_sensor_add_sensor_from_mobile_app)}
      </MText>

      <div>
        <AppStoreLogos />
      </div>
    </div>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  place-items: center;
`
